import { useEffect } from 'react';
import cx from 'classnames';

import NavItem from './NavItem';
import { scrollToElement } from '../../utils';

import './NavBar.scss';

export const NavBar = ({ className, itemWidth, navTabs, activeTab, onChange }) => {
  useEffect(() => {
    activeTab && scrollToElement('page-nav-bar', activeTab, true);
  }, []);

  return (
    <div className="nav-bar__container">
      <ul id="page-nav-bar" className={cx(`nav-bar`, className)} role="tablist">
        {navTabs.map((tab, index) => (
          <NavItem
            id={tab.value}
            key={index}
            width={itemWidth}
            Icon={tab.Icon}
            label={tab.label}
            active={tab.value === activeTab}
            onClick={() => onChange(tab.value)}
            lastTab={navTabs.length - 1 === index}
            nextTabActive={navTabs[index + 1]?.value === activeTab}
          />
        ))}
      </ul>
    </div>
  );
};
