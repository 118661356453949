import moment from 'moment';

export const getOffsetLabel = (offset) => {
  const hours = Math.floor(offset / 60);
  const minutes = offset % 60;

  return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm' : ''}`.trim();
};

export const getNearestInterval = (time, interval) => {
  return moment(time || '00:00', 'HH:mm')
    .set({
      m: Math.ceil(moment(time, 'HH:mm').minutes() / interval) * interval,
    })
    .format('HH:mm');
};

export const getTodayStartEndDates = (timezone) => {
  const startDate = new Date(moment.tz(timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss')).getTime();
  const endDate = new Date(moment.tz(moment(), timezone).format('YYYY-MM-DDTHH:mm:ss')).getTime();

  return { startDate, endDate };
};
