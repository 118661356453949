import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import SockJsClient from 'react-stomp';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import moment from 'moment';

import { ReactComponent as Delete } from '../../assets/img/icons/common/Delete.svg';
import { ReactComponent as NoInfo } from '../../assets/img/icons/common/InfoOutlined.svg';
import { ReactComponent as Exclamation } from '../../assets/img/icons/common/Warning.svg';
import { ReactComponent as Bell } from '../../assets/img/icons/header/Bell.svg';
import { ReactComponent as Info } from '../../assets/img/icons/header/Info.svg';

import {
  changeValue,
  deleteNotificationAction,
  getNextNotificationsAction,
  getNotificationsAction,
  getUnreadNotificationsCountAction,
  setNotificationsReadAction,
} from '../../redux/actions/notificationsAction';

import { getFormattedDate, getRelativeDate } from '../../utils';
import { getAccessToken } from '../../utils/authorization';
import { NotificationModal } from '../Modal/NotificationModal';
import './Notifications.scss';

const Notifications = ({
  notifications,
  getNotificationsAction,
  deleteNotificationAction,
  getNextNotificationsAction,
  getUnreadNotificationsCountAction,
  setNotificationsReadAction,
  changeValue,
}) => {
  const [tries, setTries] = useState(0);
  const [noticationsOpened, setNotificationsOpened] = useState(false);
  const notificationsRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      changeValue('loading', notifications.loading);
    }, 60000);
    notifications.unreadTotalResults === 0 && getUnreadNotificationsCountAction();
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getUnreadNotificationsCountAction();
  }, [notifications.content?.length]);

  useEffect(() => {
    if (noticationsOpened) {
      getNotificationsAction(
        {},
        {
          onSuccess: (data) => {
            if (notifications.unreadTotalResults !== 0) {
              getNotificationsAction(
                { size: data.totalElements },
                {
                  onSuccess: (items) => {
                    if (items.content.filter((item) => item.status === 'UNREAD').length > 0)
                      setNotificationsReadAction({
                        notificationsUuid: items.content
                          .filter((item) => item.status === 'UNREAD')
                          .map((item) => item.uuid),
                      });
                  },
                }
              );
            }
          },
        }
      );
    }
  }, [noticationsOpened]);

  const handleMessage = (msg) => {
    msg.eventTime = new Date().toISOString();
    changeValue('content', notifications.content !== null ? [msg, ...notifications.content] : [msg]);
  };

  return (
    <>
      <div className="header-icons-item notifications-container" onClick={() => setNotificationsOpened(true)}>
        <Bell />
        {notifications.unreadTotalResults > 0 && (
          <div className="header-bell__notifications ">{notifications.unreadTotalResults}</div>
        )}
      </div>

      {noticationsOpened && notifications.content && (
        <NotificationModal
          title="Notifications"
          modalBody={
            <div className="notifications__container" id="notification_list">
              {notifications.content.length !== 0 ? (
                <InfiniteScroll
                  style={{ overflow: 'hidden' }}
                  dataLength={20 * notifications.page}
                  next={() =>
                    getNextNotificationsAction({
                      page: notifications.page + 1,
                    })
                  }
                  hasMore={!notifications.last}
                  loader={<FontAwesomeIcon className="d-flex w-100 mb-2" icon={faSpinner} spin />}
                  scrollableTarget="notification_list"
                >
                  <ul>
                    {notifications.content.map((notification) => (
                      <li
                        key={notification.uuid}
                        className={cx('notification__li', { unread: notification.status === 'UNREAD' })}
                      >
                        <div className="dropdown-item notification__li-item d-flex">
                          {notification.type === 'INFO' ? (
                            <div className="notification-icon info">
                              <Info />
                            </div>
                          ) : (
                            <div
                              className={cx('notification-icon', {
                                error: notification.type === 'ERROR',
                                warn: notification.type === 'WARN',
                              })}
                            >
                              <Exclamation />
                            </div>
                          )}
                          <div className="notification__li-text">
                            <div
                              className="notification__li-span"
                              dangerouslySetInnerHTML={{
                                __html: notification.message,
                              }}
                            ></div>
                            <div
                              className="notification__li-time"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={getFormattedDate(notification.eventTime)}
                            >
                              {new Date(
                                new Date(notification.eventTime).getTime() -
                                  new Date().getTimezoneOffset() * 60000
                              ).getDate() === new Date().getDate()
                                ? getRelativeDate(notification.eventTime)
                                : moment(
                                    new Date(notification.eventTime).getTime() -
                                      new Date().getTimezoneOffset() * 60000
                                  ).format('DD MMM • HH:mm A')}
                            </div>
                          </div>
                        </div>
                        <div
                          className="notification__li-delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteNotificationAction(notification.uuid);
                          }}
                        >
                          <Delete />
                        </div>
                      </li>
                    ))}
                  </ul>
                </InfiniteScroll>
              ) : (
                <div className="notification__li-disabled">
                  <NoInfo />
                  <span>There are no notifications</span>
                </div>
              )}
            </div>
          }
          onCancel={() => setNotificationsOpened(false)}
        />
      )}

      <SockJsClient
        url={`${process.env.REACT_APP_URL_API}sockets/notifications?Authorization=${getAccessToken()}`}
        topics={['/secured/user/topic/notifications']}
        autoReconnect={tries < 3}
        onConnectFailure={() => setTries((prev) => prev + 1)}
        onMessage={handleMessage}
        ref={notificationsRef}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationsAction: (params, actions) => dispatch(getNotificationsAction(params, actions)),
    getUnreadNotificationsCountAction: () => dispatch(getUnreadNotificationsCountAction()),
    setNotificationsReadAction: (params) => dispatch(setNotificationsReadAction(params)),
    deleteNotificationAction: (id) => dispatch(deleteNotificationAction(id)),
    changeValue: (key, value) => dispatch(changeValue(key, value)),
    getNextNotificationsAction: (params) => dispatch(getNextNotificationsAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
