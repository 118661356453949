import moment from 'moment';
import { isNil } from 'lodash';

import { getTimezoneOffsetInSeconds } from './index';
import {
  checkSearchParameter,
  deleteSearchParameters,
  getSearchParameter,
  setSearchParameter,
  setSearchParameters,
} from './urlFunctions';
import { getTodayStartEndDates } from './time';

const getNewTimezone = (value, restaurantOptions, loginTimezone) => {
  return restaurantOptions.find((option) => option.value === value)
    ? restaurantOptions.find((option) => option.value === value).timezone
    : loginTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getCurrentDateWithTimezone = (timezone) => {
  return new Date(moment.tz(moment(), timezone).format('YYYY-MM-DDTHH:mm:ss'));
};
const getCurrentDayWithTimezone = (timezone) => getCurrentDateWithTimezone(timezone).getDate();
const getCurrentUtcWithTimezone = (timezone) => getCurrentDateWithTimezone(timezone).getTime();

const isDateEqualToCurrantDay = (date, timezone) =>
  getCurrentDayWithTimezone(timezone) === new Date(date).getDate();

const isDateMidnight = (date) => new Date(date).getHours() + new Date(date).getMinutes() === 0;

const isDatesValid = (dates, validator) => {
  return dates.every((date) => validator(date));
};

const isDateNotNull = (date) => !isNil(date);
const isDateNotSelect = (date) => date !== 'select';
const isDateNotSelectOrNull = (date) => isDateNotNull(date) && isDateNotSelect(date);
const isDateSelectOrNull = (date) => !isDateNotNull(date) || !isDateNotSelect(date);

export const changeRestaurantWithTimezone = (
  value,
  history,
  login,
  moduleState,
  changeValue,
  changeSeveralValues,
  todayDefaultDate = false
) => {
  if (value !== null) {
    if (login.restaurantOptions.length > 1 || login.timezone !== null) {
      const newTimezone = getNewTimezone(value, login.restaurantOptions, login.timezone);
      const stateTimezone = moduleState.timezone;

      if (
        todayDefaultDate &&
        (isDatesValid([moduleState.startDate, moduleState.endDate], isDateSelectOrNull) ||
          (isDateEqualToCurrantDay(moduleState.endDate, stateTimezone) &&
            isDateMidnight(moduleState.startDate)))
      ) {
        const { startDate, endDate } = getTodayStartEndDates(newTimezone);

        changeSeveralValues({
          startDate,
          endDate,
          timezone: newTimezone,
          restaurantUuid: value,
        });

        history.push(
          setSearchParameters(
            ['startDate', 'endDate', value !== 'select' ? 'restaurantUuid' : null].filter(
              (item) => !isNil(item)
            ),
            [startDate, endDate, value !== 'select' ? value : null].filter((item) => !isNil(item))
          )
        );
      } else if (
        isDatesValid([moduleState.startDate, moduleState.endDate], isDateNotNull) &&
        isDateEqualToCurrantDay(moduleState.endDate, stateTimezone) &&
        isDateMidnight(moduleState.startDate)
      ) {
        changeSeveralValues({
          endDate: getCurrentUtcWithTimezone(newTimezone),
          timezone: newTimezone,
          restaurantUuid: value,
        });

        history.push(
          setSearchParameters(
            ['startDate', 'endDate', value !== 'select' ? 'restaurantUuid' : null].filter(
              (item) => !isNil(item)
            ),
            [
              moduleState.startDate,
              getCurrentUtcWithTimezone(newTimezone),
              value !== 'select' ? value : null,
            ].filter((item) => !isNil(item))
          )
        );
      } else if (
        isDatesValid([moduleState.startDate, moduleState.endDate, stateTimezone], isDateNotSelectOrNull) &&
        !isDateMidnight(moduleState.startDate)
      ) {
        const timezoneOffset =
          getTimezoneOffsetInSeconds(stateTimezone) - getTimezoneOffsetInSeconds(newTimezone);

        changeSeveralValues({
          endDate: moduleState.endDate - timezoneOffset,
          startDate: moduleState.startDate - timezoneOffset,
          timezone: newTimezone,
          restaurantUuid: value,
        });

        history.push(
          setSearchParameters(
            ['startDate', 'endDate', value !== 'select' ? 'restaurantUuid' : null].filter(
              (item) => !isNil(item)
            ),
            [
              moduleState.startDate - timezoneOffset,
              moduleState.endDate - timezoneOffset,
              value !== 'select' ? value : null,
            ].filter((item) => !isNil(item))
          )
        );
      } else {
        changeSeveralValues({
          timezone: newTimezone,
          restaurantUuid: value,
        });
      }
    } else {
      changeValue('restaurantUuid', value);
    }
  }
};

export const changeRestaurantWithTimezoneAndSearch = (
  value,
  history,
  login,
  moduleState,
  changedSearch,
  changeValue,
  changeSeveralValues,
  todayDefaultDate = false
) => {
  if (value !== null) {
    if (login.restaurantOptions.length > 1 || login.timezone !== null) {
      const newTimezone = getNewTimezone(value, login.restaurantOptions, login.timezone);
      const stateTimezone = moduleState.timezone;

      if (
        todayDefaultDate &&
        (isDatesValid([moduleState.startDate, moduleState.endDate], isDateSelectOrNull) ||
          (isDateEqualToCurrantDay(moduleState.endDate, stateTimezone) &&
            isDateMidnight(moduleState.startDate)))
      ) {
        const { startDate, endDate } = getTodayStartEndDates(newTimezone);

        changeSeveralValues({
          startDate,
          endDate,
          timezone: newTimezone,
          interval: 0,
          restaurantUuid: value,
        });

        history.push(
          setSearchParameters(
            ['startDate', 'endDate', value !== 'select' ? 'restaurantUuid' : null].filter(
              (item) => !isNil(item)
            ),
            [startDate, endDate, value !== 'select' ? value : null].filter((item) => !isNil(item))
          )
        );
      } else if (
        isDatesValid([moduleState.startDate, moduleState.endDate], isDateNotNull) &&
        isDateEqualToCurrantDay(moduleState.endDate, stateTimezone) &&
        isDateMidnight(moduleState.startDate)
      ) {
        changeSeveralValues({
          endDate: getCurrentUtcWithTimezone(newTimezone),
          timezone: newTimezone,
          interval: 0,
          restaurantUuid: value,
        });

        if (!changedSearch) {
          history.push(
            setSearchParameters(
              ['startDate', 'endDate', value !== 'select' ? 'restaurantUuid' : null].filter(
                (item) => !isNil(item)
              ),
              [
                moduleState.startDate,
                getCurrentUtcWithTimezone(newTimezone),
                value !== 'select' ? value : null,
              ].filter((item) => !isNil(item))
            )
          );
        }
      } else if (
        isDatesValid([moduleState.startDate, moduleState.endDate, stateTimezone], isDateNotSelectOrNull) &&
        !isDateMidnight(moduleState.startDate)
      ) {
        const timezoneOffset =
          getTimezoneOffsetInSeconds(stateTimezone) - getTimezoneOffsetInSeconds(newTimezone);

        changeSeveralValues({
          endDate: moduleState.endDate - timezoneOffset,
          startDate: moduleState.startDate - timezoneOffset,
          timezone: newTimezone,
          interval: 0,
          restaurantUuid: value,
        });

        if (!changedSearch) {
          history.push(
            setSearchParameters(
              ['startDate', 'endDate', value !== 'select' ? 'restaurantUuid' : null].filter(
                (item) => !isNil(item)
              ),
              [
                moduleState.startDate - timezoneOffset,
                moduleState.endDate - timezoneOffset,
                value !== 'select' ? value : null,
              ].filter((item) => !isNil(item))
            )
          );
        }
      } else {
        changeSeveralValues({
          timezone: newTimezone,
          restaurantUuid: value,
        });

        if (
          !changedSearch &&
          login.restaurantUuid === 'select' &&
          value !== getSearchParameter('restaurantUuid')
        ) {
          value !== 'select'
            ? history.push(setSearchParameter('restaurantUuid', value))
            : checkSearchParameter('restaurantUuid') &&
              history.push(deleteSearchParameters(['restaurantUuid']));
        }
      }
    } else {
      changeValue('restaurantUuid', value);
    }
  }
};

export const getUTCTimestamp = (date, timezone) => {
  return new Date(date).getTime() - getTimezoneOffsetInSeconds(timezone);
};

export const getLocalTimestamp = (date, timezone) => {
  return new Date(date).getTime() + getTimezoneOffsetInSeconds(timezone);
};

export const getUTCNow = () => {
  return new Date().getTime() - getTimezoneOffsetInSeconds(Intl.DateTimeFormat().resolvedOptions().timeZone);
};
