import cx from 'classnames';

import { statusValues } from '../../../../constants/productionQueue.constants';
import { CancelProgress } from './CancelProgress';
import { CompletedProgress } from './CompletedProgress';
import { GoProgress } from './GoProgress';

const getProgressContent = (row, stages) => {
  if (row.isCanceled) {
    return <CancelProgress row={row} />;
  }

  if (row.isCompleted) {
    return <CompletedProgress stages={stages} row={row} />;
  }

  return <GoProgress row={row} stages={stages} />;
};

export const QueueProgress = ({ row, stages, mini = false }) => {
  if (row.statusName.value === statusValues.WAIT) {
    return null;
  }

  return <div className={cx('queue-table', { mini })}>{getProgressContent(row, stages)}</div>;
};
