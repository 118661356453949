import './styles.scss';

export const TotalContainer = ({ label, value }) => {
  return (
    <div className="total-item">
      <h5 className="total-label ">{label}</h5>
      <p className="total-value">{value}</p>
    </div>
  );
};
