import { getStageProgressWidth } from '../utils';

const getStyle = (row, stages) => {
  const stageNumber = row.status.stage || 1;

  return {
    width: getStageProgressWidth(stages, stageNumber),
    backgroundColor: row.statusName.color,
  };
};

export const CompletedProgress = ({ row, stages }) => {
  return (
    <div className="queue-table__loading" style={getStyle(row, stages)}>
      {row.statusName.label}
    </div>
  );
};
