import request from '../utils/request';

export function getCateringOrders(params, signal) {
  return request({
    url: '/catering-orders',
    method: 'get',
    data: params,
    signal,
  });
}

export function createCateringOrder(params) {
  return request({
    url: '/catering-orders',
    method: 'post',
    data: params,
  });
}

export function updateCateringOrder(uuid, params) {
  return request({
    url: `/catering-orders/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteCateringOrder(uuid) {
  return request({
    url: `/catering-orders/${uuid}`,
    method: 'delete',
  });
}
