import { useCallback, useMemo } from 'react';
import { SmallBarChart } from '../Analytics/SmallBarChart';
import { TableWithScroll } from '../TableWithScroll';
import './SmallBarChartWithTableContent.scss';
import { TotalContainer } from '../TotalContainer';
import { intervals, timeIntervals } from '../../hooks/useChartInterval';

export const SmallBarChartWithTableContent = ({
  data,
  screen,
  history,
  changeSeveralValues,
  getNextData,
  isRenderChart = true,
  customColors,
  rows,
  chartHeader,
  headerOptions,
  coloredTooltip = true,
  customFormat,
  changeValue,
  isTimersCycle,
  chartRef,
}) => {
  const isMobile = screen === 'mobile';
  const dates = useMemo(
    () => [data.startDate, data.endDate, data.timeInterval, data.interval, data.timezone],
    [data.startDate, data.endDate, data.timeInterval, data.interval, data.timezone]
  );

  const handleClickBar = useCallback(
    (startDate, endDate, interval) => {
      let currentInterval = interval;

      if (isTimersCycle) {
        const searchCalendar = timeIntervals
          .slice(0, timeIntervals.length - 1)
          .find(
            (time, index) =>
              time.value >= parseInt((endDate - startDate) / 1000) &&
              parseInt((endDate - startDate) / 1000) <= timeIntervals[index + 1].value
          );
        currentInterval = intervals[searchCalendar.auto].value;
      }

      changeSeveralValues({
        startDate,
        endDate,
        timeInterval: null,
        interval: currentInterval,
      });
    },
    [isTimersCycle]
  );

  const handleChangeInterval = useCallback((interval) => {
    if (changeValue) {
      changeValue('interval', interval);
    }
  }, []);

  const getAnalyticChart = () => {
    if (
      data.graphContent !== null &&
      data.startDate !== 'select' &&
      data.endDate !== 'select' &&
      isRenderChart
    ) {
      return (
        <SmallBarChart
          graphContent={data.graphContent}
          mobile={isMobile}
          history={history}
          dates={dates}
          total={data.total}
          customColors={customColors}
          isLoading={data.chartLoading}
          timeLimit={120000}
          chartHeader={chartHeader}
          coloredTooltip={coloredTooltip}
          clickBar={handleClickBar}
          changeInterval={handleChangeInterval}
          customFormat={customFormat}
          chartRef={chartRef}
        />
      );
    }

    return null;
  };

  return (
    <div id="scrollable-content" className="page-main-content mt-24 small-bar-chart-with-table-content">
      <div className="chart-wrapper">
        {data.total > 0 && !data.chartLoading && isRenderChart && (
          <div className="total-wrapper">
            <TotalContainer label="Total" value={`${data.total}`} />
          </div>
        )}
        {!isMobile && getAnalyticChart()}
      </div>
      {isMobile && getAnalyticChart()}
      <div className="mt-24">
        <TableWithScroll
          screen={screen}
          headerOptions={headerOptions}
          rows={rows}
          isLoading={data.loading}
          isScrollLoading={data.scrollLoading}
          existsNextData={data.existNext}
          nextData={getNextData}
          scrollableTarget="scrollable-content"
        />
      </div>
    </div>
  );
};
