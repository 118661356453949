import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as ArrowDropDown } from '../../../assets/img/icons/common/ArrowDropDown.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/common/Close.svg';
import { ReactComponent as Search } from '../../../assets/img/icons/common/Search.svg';

import {
  checkSearchParameter,
  checkSearchString,
  deleteSearchParameters,
  getSearchParameter,
  setSearchParameters,
} from '../../../utils/urlFunctions';
import './SingleSelectFilter.scss';

import { useHandleOutsideClick } from '../../../hooks/useHandleOutsideClick';
import { usePlacement } from '../../../hooks/usePlacement';

export const SingleSelectFilter = ({
  history,
  title,
  name,
  options,
  value,
  defaultValue,
  onChange,
  searchable,
  clearable,
  changedSearch,
  setChangedSearch,
  removeAlso = [],
  disabled,
  disableHistoryPush,
  rightAlign = false,
  placement = 'bottom-start',
}) => {
  const [selectOpened, setSelectOpened] = useState(false);
  const { refs, floatingStyles } = usePlacement(placement, selectOpened);
  const [searchInput, setSearchInput] = useState('');

  useHandleOutsideClick(
    selectOpened,
    () => {
      setSelectOpened(false);
    },
    refs.floating,
    refs.reference
  );

  useEffect(() => {
    if (name && history && checkSearchString() && checkSearchParameter(name) && options.length > 1) {
      let search = getSearchParameter(name);
      if (search !== null && options.map((option) => option.value.toString()).includes(search.toString())) {
        onChange(name, search);
      } else if (checkSearchString()) {
        history.push(deleteSearchParameters([name]));
      }
    }
  }, [options]);

  useEffect(() => {
    let search = getSearchParameter(name);
    if (changedSearch) {
      if (search === null && value !== 'select' && value !== defaultValue) {
        onChange(name, defaultValue ? defaultValue : 'select');
      } else if (search !== null && search !== value) {
        onChange(name, search);
      }
      setChangedSearch(false);
    } else if (value === null && history && search === null) {
      onChange(name, defaultValue ? defaultValue : 'select');
    }
  }, [changedSearch]);

  const handleReset = (event) => {
    event.stopPropagation();
    name
      ? onChange(name, defaultValue ? defaultValue : 'select')
      : onChange(defaultValue ? defaultValue : 'select');
    history && checkSearchString() && history.push(deleteSearchParameters([name, ...removeAlso]));
    setSelectOpened(false);
  };

  const handleSelectOption = (newValue) => {
    if (newValue !== value) {
      if (name) {
        onChange(name, newValue);
        if (history && !disableHistoryPush) {
          newValue !== 'select' && newValue !== defaultValue
            ? newValue !== getSearchParameter(name) &&
              history.push(setSearchParameters([name], [newValue], removeAlso))
            : checkSearchString() && history.push(deleteSearchParameters([name, ...removeAlso]));
        }
      } else onChange(newValue);
    }
    setSelectOpened(false);
  };

  const dropdownMenu = (
    <div
      ref={refs.setFloating}
      style={floatingStyles}
      className={cx('filter__single-dropdown-menu', { right: rightAlign })}
    >
      {searchable && (
        <div className="filter__single-dropdown-menu__search">
          <Search />
          <input
            type="text"
            placeholder="Search"
            autoFocus={true}
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
        </div>
      )}

      <ul className={cx('filter__single-dropdown-menu__options', { searchable })}>
        {options
          .filter((option) => option.label.toLowerCase().includes(searchInput.toLowerCase()))
          .map((option) => {
            const optionValue = option.value;

            return (
              <li
                key={option.key || optionValue}
                className={cx('option', { active: value === optionValue })}
                onClick={() => handleSelectOption(optionValue)}
              >
                <span className="option__label">{option.label}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <div
      ref={refs.setReference}
      className={cx('single-select-filter', { opened: selectOpened, disabled, clearable })}
    >
      <div
        className="filter__toggle"
        onClick={() => {
          !disabled && setSelectOpened((prev) => !prev);
        }}
      >
        <span className="filter__label">
          {title}
          {value && value !== 'select' && options.find((option) => option.value === value)
            ? `: ${options.find((option) => option.value === value).label}`
            : ''}
        </span>
        <ArrowDropDown />
        {clearable && (
          <button className="filter__clear" onClick={handleReset}>
            <Close />
          </button>
        )}
      </div>

      {selectOpened && createPortal(dropdownMenu, document.getElementById('root'))}
    </div>
  );
};
