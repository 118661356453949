import * as types from '../actions/cateringAction';

const updateCateringOrdersWithBatches = (orders, materialWithBatches = {}) => {
  return orders.map((order) => ({
    ...order,
    materials: order.materials.map((material) =>
      material.uuid === materialWithBatches.uuid
        ? { ...material, batches: materialWithBatches.batches, status: materialWithBatches.status }
        : material
    ),
  }));
};

const initialState = {
  restaurantUuid: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  limit: '20',
  loading: false,
  scrollLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  data: [],
  materialOptions: [],
};

const cateringInventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CATERING_INVENTORY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_CATERING_INVENTORY_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_CATERING_INVENTORY:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_CATERING_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data: updateCateringOrdersWithBatches(
          action.params.cursorDirection === 'NEXT'
            ? [...state.data, ...action.payload.content]
            : action.payload.content
        ),
      };

    case types.GET_CATERING_INVENTORY_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };

    case types.UPDATE_CATERING_INVENTORY_BATCHES:
      return {
        ...state,
        data: updateCateringOrdersWithBatches(state.data, action.payload),
      };

    case types.RESET_CATERING_INVENTORY_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default cateringInventoryReducer;
