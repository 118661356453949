import { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
import { BodyCell } from './Cells';
import { TableContentContainer } from './TableContentContainer';
import { DesktopTHead } from './desktop/DesktopTHead';
import { ReactComponent as ChevroneDown } from '../../assets/img/icons/common/ChevroneDown.svg';
import { ReactComponent as ChevroneUp } from '../../assets/img/icons/common/ChevroneUp.svg';

const initData = (rows) => {
  return rows.map((row) => ({
    ...row,
    isOpen: false,
  }));
};

export const HierarchicalTable = ({ rows, headerOptions, isLoading, emptyText, sortModel }) => {
  const [tableData, setTableData] = useState(() => initData(rows));

  const handleRowClick = (row) => {
    setTableData((prev) =>
      prev.map((item) => (item.id !== row.id ? item : { ...item, isOpen: !item.isOpen }))
    );
  };

  useEffect(() => {
    setTableData(initData(rows));
  }, [rows]);

  return (
    <div className="custom-table__wrapper">
      <table className="custom-table__table hierarchical-table">
        <DesktopTHead headerOptions={headerOptions} sortModel={sortModel} />
        <TableContentContainer rows={rows} isLoading={isLoading} emptyText={emptyText}>
          {tableData.map((row) => (
            <Fragment key={row.id}>
              <tr
                className={cx('custom-table__tbody-row', { selectable: row.onSelectClick })}
                style={{ height: row.minHeight || 'auto' }}
                onClick={() => handleRowClick(row)}
              >
                {row.cells.map((cell, index) => {
                  const rowCell = !cell.isArrow
                    ? cell
                    : {
                        ...cell,
                        cellComponent: row.isOpen ? <ChevroneUp /> : <ChevroneDown />,
                      };
                  return <BodyCell key={`${row.id}-${index}`} cell={rowCell} />;
                })}
              </tr>
              {row.isOpen &&
                row.items.map((subRow, i) => (
                  <tr
                    className={cx('custom-table__tbody-row subRow', {
                      'last-row': row.items.length - 1 === i,
                    })}
                    key={`${row.id}-items`}
                  >
                    {subRow.cells.map((subRowCell, i) => (
                      <BodyCell key={`${row.id}-${i}-items`} cell={subRowCell} />
                    ))}
                  </tr>
                ))}
            </Fragment>
          ))}
        </TableContentContainer>
      </table>
    </div>
  );
};
